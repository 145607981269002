
import { defineComponent, onMounted, ref } from "vue";
import { createClient } from "contentful";
import { ContentfulPage } from "./types/contentful-page.interface";
import { ContentfulCompanyInfo } from "./types/contentful-company-page.interface";
import animationData from "./assets/maintenance.json";

export default defineComponent({
  name: "App",
  setup() {
    document.title = "Novia Financial - Important Messages";
    let header = ref<string>("Scheduled Maintenance");
    let content = ref<string>("To enable scheduled maintenance, our online services are currently unavailable. We apologise for any inconvenience.");
    let strapline = ref<string>("intelligent wealth management");
    let copyright = ref<string>("© 2021 Novia Financial plc");
    let fcaRegistrationInformation = ref<string>("Novia Financial plc is a limited company registered in England &amp; Wales. Register Number: 06467886. Novia Financial plc is authorised and regulated by the Financial Conduct Authority. Register Number: 481600.  We are not responsible for the content of third party websites.");
    let phoneCharges = ref<string>("Phone lines are open 8.30am to 5pm Monday to Friday excluding bank holidays.");
    let phone = ref<string>("0345 680 8000");
    let email = ref<string>("info@novia-financial.co.uk");
    let logo = ref<string>("//images.ctfassets.net/qg5at8p9qygn/67fkpwS3IIoukGawA8CG44/f2773fbc18a50129a0e751805de7fa98/novia-financial.png");
    onMounted(async () => {
      const client = createClient({
        space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
        environment: process.env.VUE_APP_CONTENTFUL_ENVIRONMENT,
        resolveLinks: true,
      });
    
      try{
        const companyPageData = (await client.getEntry<ContentfulCompanyInfo>(`${process.env.VUE_APP_CONTENTFUL_COMPANYINFO_PAGE_ID}`, {include: 5}));
        const downtimePageData = (await client.getEntry<ContentfulPage>(`${process.env.VUE_APP_CONTENTFUL_DOWNTIME_PAGE_ID}`, {include: 5}));
     
        document.title = `${companyPageData.fields.companyName} - ${downtimePageData.fields.pageTitle}`;
        strapline.value = companyPageData.fields.companySlogan;
        copyright.value = companyPageData.fields.copyright;
        fcaRegistrationInformation.value = companyPageData.fields.fcaRegistrationInformation;
        phoneCharges.value = companyPageData.fields.phoneCharges;
        phone.value = companyPageData.fields.primaryPhoneNumber;
        email.value = companyPageData.fields.primaryEmailAddress;
        logo.value = companyPageData.fields.companyLogo.fields.file.url;

        downtimePageData.fields.labels.forEach(function (label) {        
          switch (label.fields.name)
          {
            case "Placeholder":
              header.value = label.fields.content.fields.term;
              break;
            case "MessageContent": 
              content.value = label.fields.content.fields.term;
              break;
          }
        });
      }
      catch (error){
          console.log(error);
      }
    });

    return { header, content, animationData, strapline, copyright, fcaRegistrationInformation, phoneCharges, phone, email, logo };
  },
});
